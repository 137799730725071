/*** Nav Styles ***/
.sticky-nav {
  background-color: #c8b6ff;
  transition: background-color 0.4s linear, padding 0.4s linear;
  z-index: 10;
  padding-top: 3vh;
  padding-bottom: 3vh;
}

.fixed-nav {
  transition: background-color 0.4s linear, padding 0.4s linear;
  z-index: 10;
  padding-top: 2vh;
  padding-bottom: 2vh;
}

/* bootstrap medium */
@media only screen and (max-width: 768px) {
  .fixed-nav {
    background-color: #c8b6ff;
    transition: background-color 0.4s linear, padding 0.4s linear;
    z-index: 10;
    padding-top: 2vh;
    padding-bottom: 2vh;
    position: fixed;
  }
}

/*** Nav Details ***/
.nav-text-margin {
  margin-right: 10vw;
}

.logo-img {
  width: 50%;
  height: auto;
}

.nav-txt {
  font-size: clamp(1.1rem, 1.3rem, 1.5rem);
}

.nav-brand {
  font-weight: 500;
  font-size: clamp(1.4rem, 1.6rem, 1.9rem);
}
