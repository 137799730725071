.footer {
  background-color: #e7c6ff;
  border-top: #bbd0ff 3px solid;
}

.footer-card {
  background-color: #e7c6ff;
}

.brand {
  width: 42px;
  height: auto;
  color: rgb(64, 64, 255);
}

.brand-black {
  width: 42px;
  height: auto;
  color: black;
}

.brand-small {
  width: 20px;
  height: auto;
}

.copyright {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 500;
}

ul {
  padding-left: 0;
  font-weight: 500;
  font-size: 1.2rem;
}

li {
  list-style: none;
}

.footer-link {
  font-size: 1rem;
  font-weight: 500;
  text-decoration: none;
  color: rgb(51, 51, 51);
}

.footer-link:hover {
  text-decoration: underline;
  color: black;
}
