* {
  font-family: "Poppins", sans-serif;
}

p {
  font-size: 0.9rem;
}

/* RESPONSIVE FOR MOBILE SETTING */
html,
body {
  overflow-x: hidden;
}
body {
  position: relative;
}

.App {
  min-height: 100vh;
  margin: 0;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

/*** ICONS ***/
/* align icon in the middle of text*/
.centered-label {
  display: flex;
  align-items: center;
}

/* arrow icon */
.icon {
  font-size: 1.5rem;
  margin-left: 0.5rem;
  color: rgb(247, 170, 54);
}

.icon-cart {
  font-size: 2rem;
  padding-bottom: 0.3rem;
  color: white;
}

.icon-nav-symbol {
  font-size: clamp(1rem, 4vw, 1.5rem);
  margin-left: 0.5rem;
}

.icon-paw {
  font-size: clamp(1.2rem, 4vw, 1.5rem);
  margin-bottom: 0.3rem;
}

/*** LINKS ***/

.link-text {
  text-decoration: none;
  color: rgb(51, 51, 51);
}

.link-text:hover {
  text-decoration: underline;
  color: black;
}

.link-text-white {
  text-decoration: none;
  color: white;
}

.link-text-white:hover {
  text-decoration: underline;
  color: white;
}

/*** Page Panels ***/

.panel-container {
  margin-top: 12vh;
  margin-bottom: 5vh;
}

/* Iframe for youtube vide  */
.iframe-yt {
  width: 100%;
  height: 100%;
  min-height: 350px;
}
