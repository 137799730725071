/* Panel Styles */
.panel-header {
  font-size: 2.3rem;
  border-bottom: 2px solid rgb(241, 203, 99);
  padding-bottom: 5px;
}

.panel-btn {
  background-color: #efe7da;
  border: none;
  color: rgb(35, 34, 34);
  font-size: 0.8rem;
  padding: 0.5rem 0.8rem;
  cursor: pointer;
  border-radius: 2.5rem;
  display: block;
}

.panel-btn:hover {
  background-color: #ebd7b8;
}

.panel-text {
  font-size: 1.2rem;
  text-decoration: none;
  color: black;
}

.panel-text:hover {
  text-decoration: none;
  color: black;
}

/* Card and Icons in home page */

.borderless-card {
  border: none;
}

.circle-icon {
  border-radius: 50%;
  background-color: rgb(241, 203, 99);
  padding: 10px;
  color: rgb(252, 246, 196);
  font-size: 4rem;
}

.circle-icon-text {
  font-size: 1.2rem;
  font-weight: 600;
}

.home-card-margin {
  margin-bottom: 4rem;
  margin-top: 4rem;
}

/* Contact Us page */
.vision {
  background-color: rgb(244, 247, 250);
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.mission {
  background-color: aliceblue;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.vNm-logo {
  font-size: 2rem;
}

.review-carousel {
  background-color: #9ba9ff;
  color: white;
  height: 275px;
  border-radius: 15px;
  box-shadow: 10px 10px 8px #252731;
}

.review-div-text {
  text-align: center;
  margin-top: 75px;
}

.star {
  color: rgb(255, 238, 0);
  font-size: 2rem;
}

/* bootstrap medium */
@media only screen and (max-width: 768px) {
  .vision {
    background-color: rgb(244, 247, 250);
    border-radius: 0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  .mission {
    background-color: aliceblue;
    border-radius: 0;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
}

/* Contacts */
.contact-link {
  font-size: clamp(0.5rem, 4vw, 1.8rem);
  color: black;
  text-decoration: none;
}

.contact-icon {
  font-size: 1.8rem;
}

.contact-box {
  border: gray 2px solid;
  padding: 3rem 0;
  border-radius: 15px;
  margin-bottom: 8rem;
  margin-top: 3rem;
  text-align: center;
}

.product-btn {
  background-color: #e9c083;
  border: none;
  font-size: 0.8rem;
  padding: 0.6rem 1.2rem;
  cursor: pointer;
  border-radius: 15px;
  display: block;
}

.product-btn:hover {
  background-color: #e6a543;
}
