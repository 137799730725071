.main-img {
  width: 100%;
  height: auto;
  z-index: -1;
}

.banner-img {
  top: 0px;
  display: inline-block;
  width: 100%;
  opacity: 1;
}

.banner-caption {
  background-color: #eddec5;
  color: black;
  padding: 5px 10px;
  caption-side: bottom;
}

.banner-btn {
  background-color: #d49e41;
  border: none;
  color: white;
  font-size: 1.5rem;
  padding: 0.5rem 2rem;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 8%;
}

.banner-link {
  text-decoration: none;
  color: white;
}

.banner-link:hover {
  color: #fafafa;
}

@media only screen and (max-width: 768px) {
  .banner {
    margin-top: 6rem;
  }
}
